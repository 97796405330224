import router from '@/Routes'
import FinancialPlan from '@/models/FinancialPlan'
import FinancialPlanSnapshot from '@/models/FinancialPlanSnapshot'
import WebMessage from '@/models/WebMessage'

export default [
  {
    key: 'year',
    label: 'Year',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    thStyle: 'width:200px',
  },
  {
    key: 'revision',
    label: 'Revision',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'description',
    label: 'Description',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'goal',
    label: 'Plan',
    type: 'currency',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'user',
    label: 'Created By',
    sortable: true,
    filter: false,
    show: true,
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
    type: 'object',
    object: {
      key: 'user',
      property: 'name',
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    filter: false,
    show: true,
    type: 'date',
    thClass: 'align-middle text-center',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    type: 'action_list',
    sortable: false,
    show: true,
    tdClass: 'actions',
    thStyle: { width: '200px' },
    actions: [
      {
        icon: 'eye',
        title: 'View',
        event: 'view',
        top_level: true,
        action: (plan: FinancialPlanSnapshot) => {
          // @ts-ignore
          router.push({ name: 'GoalPlan', params: { year: plan.year }, query: { snapshot: plan.id, revision: plan.revision } })
        },
      },
      {
        icon: 'download',
        title: 'Export plan',
        event: 'export',
        action: (plan: FinancialPlanSnapshot) => {
          plan.exportAnnualPlan()
        },
      },
      {
        icon: 'history',
        title: 'Restore Snapshot',
        event: 'restore',
        action: (plan: FinancialPlanSnapshot) => {
          WebMessage.doubleConfirm(
            `Are you sure that you want to restore the plan to the ${plan.year} Revision ${plan.revision}? This action can not be undone.`,
            'Restore Snapshot',
            "Yes, I've created a snapshot of the current plan and I understand that the existing plan will be deleted.",
            { okTitle: 'Save' },
          ).then(result => {
            if (result) {
              WebMessage.warning('Restoring snapshot, do not close this window. This might take a few minutes...')
              plan.restore().then(() => {
                // @ts-ignore
                router.push({ name: 'GoalHome' })
                WebMessage.success('Snapshot restored successfully!')
              })
            }
          })
        },
      },
      {
        icon: 'chart-area',
        title: 'General Overview',
        event: 'overview',
        top_level: true,
        action: (plan: FinancialPlanSnapshot) => {
          // @ts-ignore
          router.push({ name: 'GoalOverView', params: { year: plan.year }, query: { snapshot: plan.id, revision: plan.revision } })
        },
      },
    ],
  },
]
