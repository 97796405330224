import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import User from './User'
import Model from './interface/Model'
import Api from './Api'
import WebMessage from './WebMessage'

export default class FinancialPlanSnapshot extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'financial_plan_snapshots' as string | null,
      plural: 'financial_plan_snapshots' as string | null,
    },
  }

  public id: string = ''

  public name: string = ''

  public description: string = ''

  public year: number = 0

  public revision: number = 0

  public goal: number = 0

  public user_id: string = ''

  public user: null | User = null

  public created_at: string = ''

  public toObject(source: any) {
    let instance = this.clone()

    Object.assign(instance, source)

    if (source.user) {
      instance.user = User.toObject(source.user)
    }

    return instance
  }

  public exportAnnualPlan() {
    let api = new Api()

    WebMessage.success('Generating Files, do not close this window!')

    const instance_id = getModule(SystemtModule)._uuid

    return api.get(
      `financial_plan/export/${this.year}?instance_id=${instance_id}&snapshot=${this.id}`,
    )
  }

  public restore() {
    let api = new Api()

    return api.get(`financial_plan_snapshot/restore/${this.id}`)
  }
}
