
import { Component, Ref, Prop } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import Widget from '@/components/Widget/Widget.vue'
import FinancialPlanSnapshot from '@/models/FinancialPlanSnapshot'
import Fields from './financial-plan-snapshot-fields'

@Component({
  components: {
    DataTable,
    Widget,
  },
})
export default class GoalSnapshotView extends ViewModel {
  @Ref() public dataTable!: any

  @Prop({ required: true })
  public year!: any

  public ready = false

  public fieldFilters: any = {}

  public records:number = 0

  public get fields() {
    return Fields
  }

  public mounted() {
    this.ready = true
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return FinancialPlanSnapshot.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, `year:${this.year}`],
      ...this.fieldFilters,
    }).then((response: any) => {
      this.loading = false
      this.records = response.records
      return response.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }
}
